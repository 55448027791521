import React from 'react';
import {Link} from 'react-router-dom';
import {AboutWidget, CategoryWidget, IconLink, InfoMedia, RecentPostWidget, Widget} from '../../components/';

// Widget Datas
import widgetsdata from '../../data/widgets.json';
import menuLinks from "../../data/MenuLinks.json";
import blogdata from '../../data/blog.json';

const {pageList, exploreLinks, recentPosts} = widgetsdata;


const FooterOne = () => (
    <footer className="footer-wrapper footer-layout1 background-image"
            style={{backgroundImage: 'url(/images/shape/bg-footer-1-1.jpg)'}}>
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="footer-media col-sm">
                        <Link target={'_blank'} to={'https://www.google.com/maps/place/Sharjah+Media+City+(Shams)/@25.2784547,55.6762004,913m/data=!3m1!1e3!4m6!3m5!1s0x3ef5929a229f1c75:0xb28ae30eb6bb813a!8m2!3d25.2784456!4d55.6782898!16s%2Fg%2F11c6038qjv?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D'}>
                            <InfoMedia
                                icon="fal fa-map-marker-alt"
                                title="Office Address"
                                info={<div className={"custom-link"}>{"Sharjah Media City\nSharjah, UAE"}</div>}
                            />
                        </Link>
                    </div>
                    <div className="footer-media col-sm">
                        <InfoMedia
                            icon="fal fa-ticket"
                            title="Support Services"
                            info={<>
                                <a target={'_blank'} href="https://esyncross.freshdesk.com/support/login">Login to support portal</a>
                                <br/>
                                <a target={'_blank'}  href="https://esyncross.freshdesk.com/support/tickets/new">Submit new ticket</a>
                            </>}
                        />
                    </div>
                    <div className="footer-media col-sm">
                        <InfoMedia
                            icon="fal fa-phone-volume"
                            title="Contact Us"
                            info={<>
                                <a href="mailto:sales@esyncross.com">sales@esyncross.com</a>
                                <br/>
                                <a href="tel:+971585703053">+971 585703053</a>
                            </>}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="widget-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-auto">
                        <Widget widgetTitle="About Us" className="footer-widget">
                            <AboutWidget>
                                <AboutWidget.Text>
                                    eSyncross is a leading solutions architect, driving business success through
                                    tailored, innovative solutions across diverse domains and cutting-edge technologies.
                                </AboutWidget.Text>
                                <IconLink className="footer-social">
                                    <IconLink.Item icon="fab fa-linkedin"
                                                   path="https://www.linkedin.com/company/esyncross/"/>
                                </IconLink>
                            </AboutWidget>
                        </Widget>
                    </div>
                    <div className="col-6 col-xl-auto">
                        <Widget widgetTitle="Links" className="widget_nav_menu footer-widget">
                            <CategoryWidget>
                                {menuLinks.map(cat => (
                                    <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item>
                                ))}
                            </CategoryWidget>
                        </Widget>
                    </div>
                    <div className="col-6 col-xl-auto">
                        <Widget widgetTitle="Explore" className="widget_nav_menu footer-widget">
                            <CategoryWidget>
                                {exploreLinks.map(cat => (
                                    <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item>
                                ))}
                            </CategoryWidget>
                        </Widget>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <Widget widgetTitle="Lastest Post" className="footer-widget">
                            {blogdata.slice(-2).map(post => (
                                <RecentPostWidget key={post.id}
                                                  title={post.title}
                                                  image={post.images[0]}
                                                  date={post.date}
                                                  path={`/blog-details/${post.id}`}
                                />
                            ))}
                        </Widget>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container">
                <p className="copyright-text">Copyright <i className="fal fa-copyright"/> {(new Date())?.getFullYear()} <Link
                    className="text-white" to="/">eSyncross</Link>. All rights reserved
                </p>
            </div>
        </div>
    </footer>
);

export default FooterOne;